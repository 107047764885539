export function questions() {
  return [
    ["次のうち現在かかっている病気はありますか", ["うつ病", "該当しない"]],
    [
      "次のうち現在かかっている病気はありますか",
      ["糖尿病", "耐糖能異常", "該当しない"]
    ],
    [
      "睡眠時間を教えてください",
      ["5時間未満", "5~7.9時間", "8~9.9時間", "10時間以上"]
    ],
    [
      "喫煙について当てはまるものを選択してください",
      [
        "喫煙したことがない<br/>または<br/>以前に吸ったことがあるが今は吸っていない",
        "現在吸っている"
      ]
    ],
    [
      "1日の歩行時間はどのぐらいですか",
      ["30分以下", "30分～1時間", "1時間以上"]
    ],
    ["毎日誰かと話をしますか", ["はい", "いいえ"]],
    ["車の運転はしていますか", ["はい", "いいえ"]],
    ["毎日の食料品の買い物をしていますか", ["はい", "いいえ"]],
    ["畑仕事やガーデニングをしていますか", ["はい", "いいえ"]],
    [
      "社会活動に参加していますか",
      ["はい", "いいえ"],
      "社会活動…「町内会」「老人クラブ・消防団」「宗教団体」「政治団体・団体」「産業団体・業界団体」「ボランティア団体」「市民・消費者団体」「趣味の団体」「スポーツ団体・クラブ」など"
    ],
    [
      "1日にどのぐらい緑茶を飲みますか",
      ["2杯/日以下", "3-4杯/日", "5杯以上/日"]
    ],
    [
      "1日にどのぐらいコーヒーを飲みますか",
      ["飲まない", "たまに飲む<br/>または<br/>1-2杯/日", "3杯/日以上"]
    ],
    [
      "1日にどのぐらいアルコールを飲みますか",
      [
        "飲まない",
        "たまに飲む<br/>（1回の飲酒量：2単位以下）",
        "たまに飲む<br/>（1回の飲酒量：3単位以上）",
        "毎日飲む<br/>（1回の飲酒量：2単位以下）",
        "毎日飲む<br/>（1回の飲酒量：3単位以上）"
      ],
      "1単位＝ビール1本、グラスワイン1杯、日本酒0.5合として回答してください"
    ],
    [
      "1週間にどのぐらい柑橘類を食べますか",
      ["2回以下/週", "3-4回/週", "ほぼ毎日/週"]
    ],
    [
      "1回の食事でごはん（米）をどのぐらい食べますか",
      ["茶碗1杯以下", "茶碗2杯以上"]
    ],
    [
      "1週間にどのぐらい魚を食べますか",
      ["ほとんど食べない", "1-2回/週", "3-4回/週", "ほぼ毎日食べる"]
    ]
  ];
}

export function topics() {
  return [
    {
      id: 1,
      title: "医師が薦める「認知症予防」に効く習慣は？",
      question:
        "予防に良い食べ物、又は予防に適している効果的な事を教えて下さい",
      answer:
        "規則正しい生活、食事、運動、睡眠、ストレスに気をつけて、高血圧症、糖尿病、脂質異常などの生活習慣病に気をつけて下さい。<br/>一汁三菜はご存知でしょうか?<br/>ご飯を軽く1杯、お味噌汁を1杯、メインのおかずを一品(煮魚、焼き魚、刺身など)、野菜のおかずを二品(煮物、サラダ、酢のもの、漬物など)を食べられて下さい。<br/><br/><br/>揚げ物や麺類などの単品、間食、飲酒は避けて、3食前述しました食事を守ってみて下さい。<br/><br/>また、緑黄色野菜は一日350g以上摂取し、食物繊維も豊富に摂取されて下さい。<br/><br/>できれば、毎日ウォーキングをするなど、運動習慣を持たれてください。",
      url: "https://www.askdoctors.jp/topics/1156179"
    },
    {
      id: 2,
      title: "ただのど忘れ？認知症でしょうか",
      question:
        "最近、曲の題名が思い出せなかったり<br/>アルツハイマーという言葉は思い出せるのに認知症という単語をど忘れしたり<br/>10分前にこの内容でメールをしようと考えていたのに内容を度忘れしたりが多いです。(メールするという行為自体は覚えています)<br/>私生活で忙しくて心配事があり、そのストレスの可能性もありますか？<br/>身内に認知症になった人はいません。",
      answer:
        "子供の顔や名前もわからなくなったりはないですよね。火を消すことを忘れることは合っても、火を点けた行動自体の記憶がなくなったりはないですよね？多忙だったり、ストレスがあるとこれくらいのことはよくあります。これだけでは認知症を疑うことはできません。参考になれば幸いです。",
      url: "https://www.askdoctors.jp/topics/3068328"
    },
    {
      id: 3,
      title: "認知症になるのではと不安です",
      question:
        "そうした毎日の中で、「自分が認知症になったらどうなるのだろう・・・・」という不安が募ります。夫も、子どもも私にはいません。自分が認知症になったら、今私がしていることは、誰がしてくれるのだろうと考えてしまいます。答えは、「誰もいない」です。<br/><br/>認知症の進行を遅らせる薬でなく、「治す薬」「予防薬」はできないのでしょうか？<br/>両親が認知症だと、遺伝的にも罹患する確率は高まかもという本がありました。真偽のほどはどうなのでしょう？",
      answer:
        "認知症は、老化によって脳の機能が衰えて生じる側面があります。身体機能も、加齢とともに低下しやすいですが、それと同じということです。それは誰もが避けることの出来ない宿命と言えます。老化の進行をある程度遅らせることは可能ですが、失くすことは不可能です。<br/>現代は核家族から個人の時代となり、結婚しない方も増えていますし、少子化によって単身生活をされる高齢の方は非常に多いです。将来のことが御心配でしたら、都道府県の在宅介護支援センターや地域包括支援センターで御相談になられて、具体的な支援・助言をお受けになられるのもよろしいかと存じます。<br/>生活に刺激や適度なストレスがある方が、認知機能は衰え難いです。趣味を持たれたり、社会との交流をなさられたり、運動をされたりと、生き生きと毎日をお過ごしになられますことをおすすめいたします。",
      url: "https://www.askdoctors.jp/topics/3140761"
    },
    {
      id: 4,
      title: "認知症の診断ができる医療機関は",
      question:
        "最近物忘れが多くなり困ってます。認知症との関連はどうなのか心配です。<br/>近隣の病院は3か所ありますが専門的で相談が出来るような状態でないように思えます。<br/>一般病院でも相談が出来るのでしょうか？<br/>それとは別ですが予防・進展を止めるためにはどのようにすれば良いのでしょうか？<br/>日常生活、食べ物、運動等ご教示ください。",
      answer:
        "一般病院でも相談はできます。<br/>神経内科や物忘れ外来などで相談されると良いと思います。<br/>基本的には生活習慣病の予防が認知症の予防につながります。食事に注意し、適度な運動をされるなど生活習慣の改善を図ると良いと思います。",
      url: "https://www.askdoctors.jp/topics/2545995"
    },
    {
      id: 5,
      title: "うつ病だと認知症になりやすい？",
      question:
        "うつ病の人はアルツハイマーをはじめとした認知症に罹りやすいという研究論文を読みました。 <br/>脳に蓄積するアミロイドβが原因だとする仮説で不眠によりアミロイドβの代謝が阻害される事などで認知症の発症リスクが高まると記載されていました。 <br/><br/>また、若年発症であったり、うつの再発が多くある場合更にリスクが高まるとも書かれていました。 <br/><br/>私の妻が20代半ばでうつを発症し、3年ほど経った現在うつ症状は徐々に良くなっていますが、未だ不眠に悩まされている状況です。 <br/><br/>このままでは若いうちに認知症になってしまうのではないかと心配なのですが、考えすぎなのでしょうか？",
      answer:
        "うつ病自体の症状のひとつとして仮性認知症があり、認知機能障害が見られます。うつ病は治癒する疾患ですので、治癒された方では仮性認知症は軽快されますし、その後の認知症への罹患率も健常な人と同等となります。<br/>問題となるのは、うつ病をこじらせて御症状が遷延されている場合です。その場合、仮性認知症の状態が継続されますので、認知症と診断されやすいことになります。治療に積極的に取り組まれることが大切です。<br/>どうぞ、お大事にしてさしあげてください。",
      url: "https://www.askdoctors.jp/topics/2946972"
    },
    {
      id: 6,
      title: "認知症予防につながる食生活の基本は",
      question:
        "予防に良い食べ物、又は予防に適している効果的な事を教えて下さい",
      answer:
        "認知症の危険因子として、生活習慣病が挙げられています。生活習慣病は、高血圧・糖尿病・高脂血症・肥満・喫煙などが原因で、動脈硬化が進行することで起こります。<br/>一応青魚(EPAやDHCなどの油が良いのです)・中国茶・トウガラシ・大豆製品などが良いことはよく知られています。ポリフェノール・ビタミンEなどの抗酸化物質なども動脈硬化の予防には良いようです。<br/>米ぬかに福慣れているフェルラ酸も、認知症予防には効果があると思われます。私はフェルラ酸を主体としたサプリメントを使用していますが、サプリメント服用後は、短期記憶の低下やb前頭葉機能の低下に効果があるのを、実感しております。",
      url: "https://www.askdoctors.jp/topics/1156179"
    },
    {
      id: 7,
      title: "認知症予防になる「脳に優しい習慣」",
      question:
        "魚をよく食べる人は認知症になりにくいですか．あとひらがなたし算を見たのですか例えばいちたすさんひくいち(1＋3ー1)　3みたいな計算は脳にいい効果がありますか。",
      answer:
        "適度な運動が良いとされていますよ。<br/>計算や新聞を読むなども効果は期待できます。",
      url: "https://www.askdoctors.jp/topics/2951116"
    },
    {
      id: 8,
      title: "物忘れが増え不安。何をしたら？",
      question:
        "物忘れが増えたりたまに吃音もあり心配になりインターネットのブログの作成やタッチタイピングなど認知症予防になるのではと思い始めました。効果は期待できますか。それから、ほかに良いものがあれば教えていただきたいのですが。",
      answer:
        "頭を使う活動は良いでしょうが、医学的に予防になるかどうかは不明です。<br/>脳トレなどは効果があるという意見もありますが、効果はなかったという反証もたくさん出されています。<br/>適度な運動は予防になるというデータがあります。<br/>あとは、高血圧、糖尿病、脂質異常症などの管理が大事です",
      url: "https://www.askdoctors.jp/topics/2821081"
    },
    {
      id: 9,
      title: "若いうちからすべき認知症予防策",
      question:
        "将来、認知症にならないために対策ありますか?<br/>また脳の萎縮しない対策ありますか?",
      answer:
        "認知症にならない方法は、脳を良く使用することが基本的には大切なことです。<br/><br/>アルコールのよって脳は著しく萎縮してしまいますので、食生活や生活習慣なども影響すると思います。",
      url: "https://www.askdoctors.jp/topics/512854"
    },
    {
      id: 10,
      title: "認知症予防に効くオススメ「脳トレ」",
      question:
        "将来の為に今から認知症予防をしたいなと思っています。現在は専業主婦で毎日あまり脳を使ってないのでどのような事をすれば脳を鍛えられますか？<br/>脳のトレーニングでオススメはありますか？",
      answer:
        "今の年代であれば、何かテーマを見つけて調べる、人と交流をするなどでしょう。仕事をしていればそれもトレーニングでしょう。<br/>ただしこれがいいという、確立したトレーニング内容はないと思いますよ。",
      url: "https://www.askdoctors.jp/topics/2393747"
    },
    {
      id: 11,
      title: "将来の認知症が不安…今できることは？",
      question:
        "「自分が認知症になったらどうなるのだろう・・・・」という不安が募ります。夫も、子どもも私にはいません。自分が認知症になったら、今私がしていることは、誰がしてくれるのだろうと考えてしまいます。答えは、「誰もいない」です。<br/><br/>認知症の進行を遅らせる薬でなく、「治す薬」「予防薬」はできないのでしょうか？",
      answer:
        "認知症は、老化によって脳の機能が衰えて生じる側面があります。身体機能も、加齢とともに低下しやすいですが、それと同じということです。それは誰もが避けることの出来ない宿命と言えます。老化の進行をある程度遅らせることは可能ですが、失くすことは不可能です。<br/>現代は核家族から個人の時代となり、結婚しない方も増えていますし、少子化によって単身生活をされる高齢の方は非常に多いです。将来のことが御心配でしたら、都道府県の在宅介護支援センターや地域包括支援センターで御相談になられて、具体的な支援・助言をお受けになられるのもよろしいかと存じます。<br/>生活に刺激や適度なストレスがある方が、認知機能は衰え難いです。趣味を持たれたり、社会との交流をなさられたり、運動をされたりと、生き生きと毎日をお過ごしになられますことをおすすめいたします。<br/>どうぞ、御大切になさってください。",
      url: "https://www.askdoctors.jp/topics/3140761"
    },
    {
      id: 12,
      title: "「お酒好き」だと認知症になりやすい？",
      question:
        "43歳男性です。お酒が好きで週に2回くらい休肝日はありますが、飲む時は適量をかなり超えてしまってます。今のところ肝機能は正常ですが、過度な飲酒は将来の認知症を引き起こす可能性はあるでしょうか？",
      answer:
        "過度な飲酒によって脳が萎縮を起こしたり、認知機能に影響したりということはあるかもしれません。<br/>継続して多量の飲酒を続けることで起こりうるのでしょうが、休肝日を設け、量に気を付ければ<br/>心配は無いと思います。",
      url: "https://www.askdoctors.jp/topics/2990293"
    },
    {
      id: 13,
      title: "アルコール依存症と認知症の関係は",
      question:
        "今年で断酒生活を５年になります。<br/>アルコール依存症や大量飲酒によって、認知症のリスクが普通の人よりも高いと聞いた事があるのですが、本当でしょうか？",
      answer:
        "アルコールは年齢にかかわらず認知症を引き起こします。純粋な糖質で他の栄養素を含まないため、栄養失調から脳萎縮とウェルニッケ・コルサコフ症候群を起こす他、高血圧の原因となるため微小脳梗塞が起こりやすく、血管性認知症をも惹起します。断酒を継続なさられてくださいね。どうぞ、御大切になさってください。",
      url: "https://www.askdoctors.jp/topics/2853765"
    },
    {
      id: 14,
      title: "認知症に効く食生活の基本は",
      question:
        "予防に良い食べ物、又は予防に適している効果的な事を教えて下さい",
      answer:
        "認知症の危険因子として、生活習慣病が挙げられています。生活習慣病は、高血圧・糖尿病・高脂血症・肥満・喫煙などが原因で、動脈硬化が進行することで起こります。<br/>一応青魚(EPAやDHCなどの油が良いのです)・中国茶・トウガラシ・大豆製品などが良いことはよく知られています。ポリフェノール・ビタミンEなどの抗酸化物質なども動脈硬化の予防には良いようです。<br/>米ぬかに福慣れているフェルラ酸も、認知症予防には効果があると思われます。私はフェルラ酸を主体としたサプリメントを使用していますが、サプリメント服用後は、短期記憶の低下やb前頭葉機能の低下に効果があるのを、実感しております。",
      url: "https://www.askdoctors.jp/topics/1156179"
    },
    {
      id: 15,
      title: "野菜と魚中心の食生活。認知症予防には？",
      question:
        "牛肉をほとんど、たべず、1週間に1回豚肉を食べ、魚と野菜中心ですが、認知症になりますか?",
      answer:
        "非常によい食生活だと思います。 <br/>魚も青魚にされますと逆に認知症予防の食生活になります。 <br/>納豆、豆腐、青魚、塩分控えめな和食中心が最良ですよ。",
      url: "https://www.askdoctors.jp/topics/1914244"
    }
  ];
}

export function advices() {
  return [
    {
      id: 0,
      conditions: { ans: 0, ages: [2, 3, 4, 5, 6], gender: undefined },
      positive: "うつ病にかかっていない人に比べて認知症発症リスクが2.2倍です",
      negative:
        "60歳以上の時にうつ病にかかっている場合、かかっていない人に比べて認知症発症リスクが2.2倍になります",
      topicIds: [5]
    },
    {
      id: 0,
      conditions: { ans: 1, ages: undefined, gender: undefined },
      positive: "問題ありません",
      negative: "問題ありません"
    },
    {
      id: 1,
      conditions: { ans: 0, ages: [2, 3, 4, 5, 6], gender: undefined },
      positive: "糖尿病にかかっていない人に比べて認知症発症リスクが1.7倍です",
      negative:
        "60歳以上の時に糖尿病にかかっている場合、かかっていない人に比べて認知症発症リスクが1.7倍になります",
      topicIds: [6]
    },
    {
      id: 1,
      conditions: { ans: 1, ages: [2, 3, 4, 5, 6], gender: undefined },
      positive:
        "耐糖能異常にかかっていない人に比べて認知症発症リスクが1.4倍です",
      negative:
        "60歳以上の時に耐糖能異常にかかっている場合、かかっていない人に比べて認知症発症リスクが1.4倍になります",
      topicIds: [6]
    },
    {
      id: 1,
      conditions: { ans: 2, ages: undefined, gender: undefined },
      positive: "問題ありません",
      negative: "問題ありません"
    },
    {
      id: 2,
      conditions: { ans: 0, ages: [2, 3, 4, 5, 6], gender: undefined },
      positive: "睡眠時間が5～7.9時間の人に比べて認知症発症リスクが2.6倍です",
      negative:
        "60歳以上の時に5時間未満の睡眠時間だった場合、5～7.9時間の人に比べて認知症発症リスクが2.6倍になります"
    },
    {
      id: 2,
      conditions: { ans: 1, ages: undefined, gender: undefined },
      positive: "問題ありません",
      negative: "問題ありません"
    },
    {
      id: 2,
      conditions: { ans: 2, ages: [2, 3, 4, 5, 6], gender: undefined },
      positive: "問題ありません",
      negative: "問題ありません"
    },
    {
      id: 2,
      conditions: { ans: 3, ages: [2, 3, 4, 5, 6], gender: undefined },
      positive: "問題ありません",
      negative: "問題ありません"
    },
    {
      id: 3,
      conditions: { ans: 0, ages: [3, 4, 5, 6], gender: undefined },
      positive: "問題ありません",
      negative: "問題ありません"
    },
    {
      id: 3,
      conditions: { ans: 0, ages: [0, 1, 2], gender: undefined },
      positive: "問題ありません",
      negative: "問題ありません"
    },
    {
      id: 3,
      conditions: { ans: 1, ages: [3, 4, 5, 6], gender: undefined },
      positive: "非喫煙者に比べて認知症発症リスクが1.7倍です",
      negative:
        "65歳以上の時に喫煙している場合、非喫煙者に比べて認知症発症リスクが1.7倍になります",
      topicIds: [6]
    },
    {
      id: 3,
      conditions: { ans: 1, ages: [0, 1, 2], gender: undefined },
      positive: "非喫煙者に比べて認知症発症リスクが2.1倍です",
      negative: "問題ありません",
      topicIds: [6]
    },
    {
      id: 4,
      conditions: { ans: 0, ages: [3, 4], gender: undefined },
      positive: "問題ありません",
      negative: "1日の歩行時間を30分以上にすることで、リスクが0.8倍になります",
      topicIds: [7]
    },
    {
      id: 4,
      conditions: { ans: 0, ages: [5, 6], gender: undefined },
      positive: "問題ありません",
      negative: "1日の歩行時間を30分以上にすることで、リスクが0.8倍になります",
      topicIds: [7]
    },
    {
      id: 4,
      conditions: { ans: 1, ages: [3, 4], gender: undefined },
      positive: "歩行時間30分以下の人に比べて、認知症発症リスクが0.8倍です",
      negative:
        "65歳以上の時に1日30分~1時間歩くことで、歩行時間30分以下の人に比べて認知症発症リスクが0.8倍になります"
    },
    {
      id: 4,
      conditions: { ans: 1, ages: [5, 6], gender: undefined },
      positive: "歩行時間30分以下の人に比べて、認知症発症リスクが0.8倍です",
      negative: "問題ありません"
    },
    {
      id: 4,
      conditions: { ans: 2, ages: [3, 4], gender: undefined },
      positive: "歩行時間30分以下の人に比べて、認知症発症リスクが0.6倍です",
      negative:
        "65歳以上の時に1日1時間以上歩くことで、歩行時間30分以下の人に比べて認知症発症リスクが0.6倍になります"
    },
    {
      id: 4,
      conditions: { ans: 2, ages: [5, 6], gender: undefined },
      positive: "歩行時間30分以下の人に比べて、認知症発症リスクが0.8倍です",
      negative: "問題ありません"
    },
    {
      id: 5,
      conditions: { ans: 0, ages: [3, 4, 5, 6], gender: undefined },
      positive: "会話無しの人に比べて、認知症発症リスクが0.6倍です",
      negative:
        "65歳以上の時に毎日誰かと話をすることで、会話無しの人に比べて認知症発症リスクが0.6倍になります"
    },
    {
      id: 5,
      conditions: { ans: 1, ages: [3, 4, 5, 6], gender: undefined },
      positive: "毎日誰かと話をすることで、リスクが0.6倍になります",
      negative:
        "65歳以上の時に毎日誰かと話をすることで、会話無しの人に比べて認知症発症リスクが0.6倍になります",
      topicIds: [8]
    },
    {
      id: 6,
      conditions: { ans: 0, ages: [3, 4, 5, 6], gender: undefined },
      positive: "運転しない人に比べて、認知症発症リスクが0.7倍です",
      negative: "問題ありません"
    },
    {
      id: 6,
      conditions: { ans: 1, ages: [3, 4, 5, 6], gender: undefined },
      positive: "問題ありません",
      negative: "問題ありません"
    },
    {
      id: 7,
      conditions: { ans: 0, ages: [3, 4, 5, 6], gender: undefined },
      positive: "買い物しない人に比べて、認知症発症リスクが0.6倍です",
      negative:
        "65歳以上の時に毎日の食料品の買い物をすることで、買い物しない人に比べて認知症発症リスクが0.6倍になります"
    },
    {
      id: 7,
      conditions: { ans: 1, ages: [3, 4, 5, 6], gender: undefined },
      positive: "毎日の食料品の買い物をすることで、リスクが0.6倍になります",
      negative:
        "65歳以上の時に毎日の食料品の買い物をすることで、買い物しない人に比べて認知症発症リスクが0.6倍になります",
      topicIds: [8]
    },
    {
      id: 8,
      conditions: { ans: 0, ages: [3, 4, 5, 6], gender: undefined },
      positive:
        "畑仕事やガーデニングをしていない人に比べて、認知症発症リスクが0.7倍です",
      negative:
        "65歳以上の時に畑仕事やガーデニングをすることで、しない人に比べて認知症発症リスクが0.7倍になります"
    },
    {
      id: 8,
      conditions: { ans: 1, ages: [3, 4, 5, 6], gender: undefined },
      positive: "畑仕事やガーデニングをすることで、リスクが0.7倍になります",
      negative:
        "65歳以上の時に畑仕事やガーデニングをすることで、しない人に比べて認知症発症リスクが0.7倍になります",
      topicIds: [8]
    },
    {
      id: 9,
      conditions: { ans: 0, ages: undefined, gender: undefined },
      positive:
        "社会活動に参加していない人に比べて、認知症発症リスクが0.8倍です",
      negative:
        "65~74歳の時に社会活動に参加すると、参加していない人に比べて認知症発症リスクが0.8倍になります"
    },
    {
      id: 9,
      conditions: { ans: 1, ages: [3, 4], gender: undefined },
      positive: "社会活動に参加している人に比べて、認知症発症リスクが1.2倍です",
      negative:
        "65~74歳の時に社会活動に参加すると、参加していない人に比べて認知症発症リスクが0.8倍になります",
      topicIds: [10, 11]
    },
    {
      id: 10,
      conditions: { ans: 0, ages: [3, 4, 5, 6], gender: undefined },
      positive: "問題ありません",
      negative: "毎日5杯の緑茶を飲むことで、リスクが0.7倍になります"
    },
    {
      id: 10,
      conditions: { ans: 1, ages: [3, 4, 5, 6], gender: undefined },
      positive: "問題ありません",
      negative: "1日2杯以下の人と比べて、認知症発症リスクが0.9倍です"
    },
    {
      id: 10,
      conditions: { ans: 2, ages: [3, 4, 5, 6], gender: undefined },
      positive: "問題ありません",
      negative: "1日2杯以下の人と比べて、認知症発症リスクが0.7倍です"
    },
    {
      id: 11,
      conditions: { ans: 0, ages: [3, 4, 5, 6], gender: undefined },
      positive: "問題ありません",
      negative: "問題ありません"
    },
    {
      id: 11,
      conditions: { ans: 1, ages: [3, 4, 5, 6], gender: undefined },
      positive: "コーヒーを飲まない人と比べて、認知症発症リスクが0.7倍です",
      negative:
        "65歳以上の時にコーヒーを1日~2杯程度飲んでいると、飲まない人と比べて認知症発症リスクは0.7倍になります"
    },
    {
      id: 11,
      conditions: { ans: 2, ages: [3, 4, 5, 6], gender: undefined },
      positive: "コーヒーを飲まない人と比べて、認知症発症リスクが0.8倍です",
      negative:
        "65歳以上の時にコーヒーを1日3杯以上飲んでいると、飲まない人と比べて認知症発症リスクは0.8倍になります"
    },
    {
      id: 12,
      conditions: { ans: 0, ages: undefined, gender: undefined },
      positive: "問題ありません",
      negative: "問題ありません"
    },
    {
      id: 12,
      conditions: { ans: 1, ages: [3, 4, 5, 6], gender: 0 },
      positive: "飲まない人と比べて、認知症発症リスクは0.9倍です",
      negative:
        "65歳以上の時の適度なアルコール摂取は、認知症発症リスクを下げます"
    },
    {
      id: 12,
      conditions: { ans: 1, ages: [3, 4, 5, 6], gender: 1 },
      positive: "飲まない人と比べて、認知症発症リスクは0.8倍です",
      negative:
        "65歳以上の時の適度なアルコール摂取は、認知症発症リスクを下げます"
    },
    {
      id: 12,
      conditions: { ans: 3, ages: [3, 4, 5, 6], gender: 0 },
      positive: "飲まない人と比べて、認知症発症リスクは0.8倍です",
      negative:
        "65歳以上の時の適度なアルコール摂取は、認知症発症リスクを下げます"
    },
    {
      id: 12,
      conditions: { ans: 3, ages: [3, 4, 5, 6], gender: 1 },
      positive: "飲まない人と比べて、認知症発症リスクは0.9倍です",
      negative:
        "65歳以上の時の適度なアルコール摂取は、認知症発症リスクを下げます"
    },
    {
      id: 12,
      conditions: { ans: 2, ages: undefined, gender: undefined },
      positive: "適度な飲酒（1日2単位以下）にすると認知症のリスクが下がります",
      negative:
        "65歳以上の時の適度なアルコール摂取は、認知症発症リスクを下げます",
      topicIds: [12]
    },
    {
      id: 12,
      conditions: { ans: 4, ages: undefined, gender: undefined },
      positive: "適度な飲酒（1日2単位以下）にすると認知症のリスクが下がります",
      negative:
        "65歳以上の時の適度なアルコール摂取は、認知症発症リスクを下げます",
      topicIds: [12]
    },
    {
      id: 13,
      conditions: { ans: 0, ages: [3, 4, 5, 6], gender: undefined },
      positive:
        "柑橘類を食べる頻度を3-4回/週に増やすことで、リスクが0.8倍になります",
      negative:
        "65歳以上の時に、柑橘類を週3-4回食べることで、認知症発症リスクが0.8倍になります"
    },
    {
      id: 13,
      conditions: { ans: 1, ages: [3, 4, 5, 6], gender: undefined },
      positive: "2回以下/週の人と比べて、認知症発症リスクは0.8倍です",
      negative:
        "65歳以上の時に、柑橘類を週3-4回食べることで、認知症発症リスクが0.8倍になります"
    },
    {
      id: 13,
      conditions: { ans: 2, ages: [3, 4, 5, 6], gender: undefined },
      positive: "2回以下/週の人と比べて、認知症発症リスクは0.8倍です",
      negative:
        "65歳以上の時に、柑橘類を週3-4回食べることで、認知症発症リスクが0.8倍になります"
    },
    {
      id: 14,
      conditions: { ans: 0, ages: [2, 3, 4, 5], gender: undefined },
      positive: "問題ありません",
      negative: "問題ありません"
    },
    {
      id: 14,
      conditions: { ans: 1, ages: [2, 3, 4, 5], gender: undefined },
      positive:
        "大豆・大豆製品、野菜、藻類、牛乳・乳製品の摂取量を増やし、米の摂取量を減らすことで、認知症発症リスクを下げることができます",
      negative:
        "60~79歳の時に、ごはん少な目の食生活をしていると、認知症発症リスクを下げることができます",
      topicIds: [14, 15]
    },
    {
      id: 15,
      conditions: { ans: 0, ages: [3, 4, 5, 6], gender: undefined },
      positive: "魚を食べることで、認知症発症リスクを下げることができます",
      negative:
        "65歳以上の時に魚を食べることで、認知症発症リスクを下げることができます",
      topicIds: [7, 14, 15]
    },
    {
      id: 15,
      conditions: { ans: 1, ages: [3, 4, 5, 6], gender: undefined },
      positive: "問題ありません",
      negative: "問題ありません"
    },
    {
      id: 15,
      conditions: { ans: 2, ages: [3, 4, 5, 6], gender: undefined },
      positive: "問題ありません",
      negative: "問題ありません"
    },
    {
      id: 15,
      conditions: { ans: 3, ages: [3, 4, 5, 6], gender: undefined },
      positive: "問題ありません",
      negative: "問題ありません"
    }
  ];
}
